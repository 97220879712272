import { Component } from '@angular/core';

@Component({
  selector: 'app-km-main-content',
  templateUrl: './km-main-content.component.html',
  styleUrls: ['./km-main-content.component.scss']
})
export class KmMainContentComponent {

}
