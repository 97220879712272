<div class="d-flex flex-column justify-content-center align-items-center blue-bg pt-5">
  <div class="text-white text-center w-75 mt-5 mb-5">
    <div class="row d-flex justify-content-center">
      <div class="col-md-6 text-center">
        <h1 class="attention-header">Du hast noch Fragen oder willst vorher mehr erfahren?</h1>
      </div>
    </div>
    <div *ngIf="!mobileView" class="row d-flex justify-content-center">
      <div class="col-sm-1">
        <hr class="hr" style="border-top: 3px solid white;"/>
      </div>
    </div>
    <div *ngIf="mobileView" class="row d-flex justify-content-center">
      <div class="col-4">
        <hr class="hr" style="border-top: 3px solid white;"/>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-5 mb-5">
      <div class="col-sm-4">
        <a class="btn btn-primary btn-lg button-orange" style="border-radius: 0; font-size: 1rem;" href="#contact" role="button">DIREKT KONTAKT AUFNEHMEN</a>
      </div>
    </div>
  </div>
</div>
