<div class="d-flex flex-column justify-content-center align-items-center pt-5">
  <div class="text-center w-75 mt-5 m-0">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-6 text-center">
        <a id="partner"></a>
        <h1 class="partner-header">Warum Kältemeister</h1>
      </div>
    </div>
    <div *ngIf="!mobileView" class="row d-flex justify-content-center">
      <div class="col-sm-1">
        <hr class="hr" style="border-top: 3px solid #00A4CB;"/>
      </div>
    </div>
    <div *ngIf="mobileView" class="row d-flex justify-content-center">
      <div class="col-4">
        <hr class="hr" style="border-top: 3px solid #00A4CB;"/>
      </div>
    </div>
    <div class="row d-flex justify-content-evenly mt-5 mb-5 m-0 fw-bold">
      <div class="col-md-2 text-center">
        <p>Mehr als 25 Jahre Erfahrung in Kältetechnik</p>
      </div>
      <div class="col-md-2 text-center">
        <p>Planung, Installation und Wartung</p>
      </div>
      <div class="col-md-2 text-center">
        <p>Beratung und Rundum-Service vom Profi</p>
      </div>
      <div class="col-md-2 text-center text-break">
        <p>Zertifiziert nach §6 Chem. Klima&shy;schutz&shy;verord&shy;nung</p>
      </div>
      <div class="col-md-2 text-center text-break">
        <p>Hygiene&shy;schulung gem. VDI 6022</p>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-lg-6 text-center mt-5 m-0">
        <a id="partner"></a>
        <h2 class="partner-header">Unsere Kooperationspartner</h2>
      </div>
    </div>
    <div class="row lg d-flex align-items-center mt-5 mb-5 m-0 pb-3">
      <div class="col-md-4 p-2 text-center">
        <a href="{{ thermotecPage }}" target="_blank" rel="noopener noreferrer">
          <img
            src="../../assets/img/_Kooperationspartner/Kaeltemeister-Kooperationspartner-Thermotec.jpg"
            class="img-fluid w-50 rounded"
            alt="Kältemeister Partner Thermo-Tec Logo"
          />
        </a>
      </div>
      <div class="col-md-4 p-2 text-center">
        <a href="{{ twfPage }}" target="_blank" rel="noopener noreferrer">
          <img
            src="../../assets/img/_Kooperationspartner/Kaeltemeister-Kooperationspartner-TWF.jpg"
            class="img-fluid w-50 rounded"
            alt="Kältemeister Partner TWF Logo"
          />
        </a>
      </div>
      <div class="col-md-4 p-2 text-center">
        <a href="{{ caverionPage }}" target="_blank" rel="noopener noreferrer">
          <img
            src="../../assets/img/_Kooperationspartner/Kaeltemeister-Kooperationspartner-Caverion.jpg"
            class="img-fluid w-50 rounded"
            alt="Kältemeister Partner Caverion Logo"
          />
        </a>
      </div>
      <a id="contact"></a>
    </div>
  </div>
</div>
