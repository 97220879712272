<div *ngIf="success">
  <div class="modal-header success-bg">
    <h5 class="modal-title">Erledigt!</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">Deine Nachricht wurde an die Kältemeister übermittelt!</div>
</div>

<div *ngIf="!success">
  <div class="modal-header failure-bg">
    <h5 class="modal-title">Fehler</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">Da ist wohl etwas schief gelaufen. Probiere es bitte noch einmal oder kontaktiere uns bitte per Telefon!</div>
</div>
