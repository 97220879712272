import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-km-header',
  templateUrl: './km-header.component.html',
  styleUrls: ['./km-header.component.scss']
})
export class KmHeaderComponent implements OnInit {

  mobileView = false;

  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView = true;
        } else {
          this.mobileView = false;
        }
      });
  }
}
