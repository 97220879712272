<app-km-header></app-km-header>
<div class="d-flex flex-column justify-content-center align-items-center m-0">
  <div class="text-start justify-content-center align-items-center m-5">
    <div class="row d-flex justify-content-center">
      <h2>Datenschutzerklärung</h2>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h3>Allgemeiner Hinweis und Pflichtinformationen</h3>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Benennung der verantwortlichen Stelle</h4>
      <p class="m-0">Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
      <p class="mt-3 m-0"><span id="s3-t-firma">Kältemeister</span><br><span id="s3-t-ansprechpartner">Falk Herrmann</span><br><span id="s3-t-strasse">Manfred-von-Ardenne-Ring 20 Haus D</span><br><span id="s3-t-plz">01099</span> <span id="s3-t-ort">Dresden</span></p>
      <p class="mt-3 m-0">Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
      <p class="m-0">Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h4>
      <p class="m-0">Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Recht auf Datenübertragbarkeit</h4>
      <p class="m-0">Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h4>
      <p class="m-0">Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>SSL- bzw. TLS-Verschlüsselung</h4>
      <p class="m-0">Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h3>Kontaktformular</h3>
      <p class="m-0">Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</p>
      <p class="mt-3 m-0">Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
      <p class="mt-3 m-0">Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h3>Google Web Fonts</h3>
      <h4>Art und Umfang der Verarbeitung</h4>
      <p class="m-0">Wir verwenden Google Fonts von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, als Dienst zur Bereitstellung von Schriftarten für unser Onlineangebot. Um diese Schriftarten zu beziehen, stellen Sie eine Verbindung zu Servern von Google Ireland Limited her, wobei Ihre IP-Adresse übertragen wird.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Zweck und Rechtsgrundlage</h4>
      <p class="m-0">Der Einsatz von Google Fonts erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.</p>
      <p class="mt-3 m-0">Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (z.B. in den USA) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. Dies sind – sofern nicht anders angegeben – Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. Eine Kopie dieser Standardvertragsklauseln können Sie unter <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE</a> einsehen.</p>
      <p class="mt-3 m-0">Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Speicherdauer</h4>
      <p class="m-0">Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Google Ireland Limited bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Google Fonts: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h3>Font Awesome</h3>
      <h4>Art und Umfang der Verarbeitung</h4>
      <p class="m-0">Wir verwenden zur ordnungsgemäßen Bereitstellung der Inhalte unserer Website Font Awesome des Anbieters Fonticons, Inc..</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Zweck und Rechtsgrundlage</h4>
      <p class="m-0">Der Einsatz von Font Awesome erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.</p>
      <p class="mt-3 m-0">Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (z.B. in den USA) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. Dies sind – sofern nicht anders angegeben – Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. Eine Kopie dieser Standardvertragsklauseln können Sie unter <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE</a> einsehen.</p>
      <p class="mt-3 m-0">Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Speicherdauer</h4>
      <p class="m-0">Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Fonticons, Inc. bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Font Awesome CDN: <a href="https://cdn.fontawesome.com/privacy">https://cdn.fontawesome.com/privacy</a>.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <p class="m-0"><small>Quelle: Datenschutz-Konfigurator von <a href="https://www.mein-datenschutzbeauftragter.de" target="_blank">Mein-Datenschutzbeauftragter.de</a></small></p>
    </div>
  </div>
</div>
<app-km-footer></app-km-footer>
