import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-km-services',
  templateUrl: './km-services.component.html',
  styleUrls: ['./km-services.component.scss']
})
export class KmServicesComponent implements OnInit {
  isClimateReadMore:boolean = true;
  isHeatpumpReadMore:boolean = true;
  isColdtechReadMore:boolean = true;
  isServerReadMore:boolean = true;
  isDensityCheckReadMore:boolean = true;
  isMaintenanceReadMore:boolean = true;
  isConsultingReadMore:boolean = true;

  mobileView = false;

  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView = true;
        } else {
          this.mobileView = false;
        }
      });
  }
}
