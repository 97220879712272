import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators'


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private mail = 'https://kaeltemeister.de/mailing'

  constructor(private http: HttpClient) {
  }

  PostMessage(input: any) {
    let header = new HttpHeaders()
    header = header.append('Access-Control-Allow-Origin', ['kaeltemeister.de']);
    console.log(header)
    return this.http.post(this.mail, input, { headers: header, responseType: 'text' })
      .pipe(
        map(
          (response) => {
            if (response) {
              return response;
            }else{
              return null;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
}
