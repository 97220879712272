import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-km-partner',
  templateUrl: './km-partner.component.html',
  styleUrls: ['./km-partner.component.scss']
})
export class KmPartnerComponent implements OnInit {
  twfPage = 'http://www.twf-freital.de/';
  thermotecPage = 'https://thermo-tec.de/';
  caverionPage = 'https://www.caverion.de/';

  mobileView = false;

  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView = true;
        } else {
          this.mobileView = false;
        }
      });
  }
}
