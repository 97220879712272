import { Component } from '@angular/core';

@Component({
  selector: 'app-km-impressum',
  templateUrl: './km-impressum.component.html',
  styleUrls: ['./km-impressum.component.scss']
})
export class KmImpressumComponent {
  navbarCollapsed = false;
  constructor() { }

  ngOnInit() { }
}
