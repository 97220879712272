<header>
  <!-- Background image -->
  <div class="text-center bg-image header-background">

    <div *ngIf="!mobileView" class="mask">
      <div class="d-flex justify-content-center h-25 mt-5 mb-3 m-0">
        <img src="../../assets/img/KAELTEMEISTER-LOGO-NEG.png">
      </div>
      <div class="d-flex justify-content-center h-75">
        <div class="text-white">
          <h1 class="display-1 mt-5 mb-3 m-0" style="font-family: kmHeader;">Raumklima nach Maß.</h1>
          <h4 class="h2" style="font-family: kmHeader;">Von frostig bis kuschelig.</h4>
          <a class="btn btn-primary btn-lg button-orange mt-5" style="border-radius: 0; font-size: 1rem;" href="#contact" role="button">DIREKT KONTAKT AUFNEHMEN</a
          >
        </div>
      </div>
    </div>
    <div *ngIf="mobileView" class="mask">
      <div class="d-flex justify-content-center h-25 mt-5 mb-3 m-0">
        <img class="header-img" src="../../assets/img/KAELTEMEISTER-LOGO-NEG.png">
      </div>
      <div class="d-flex justify-content-center h-75">
        <div class="text-white">
          <h1 class="display-3 mt-5 mb-1 m-0" style="font-family: kmHeader;">Raumklima nach Maß.</h1>
          <h4 class="h3" style="font-family: kmHeader;">Von frostig bis kuschelig.</h4>
          <a class="btn btn-primary btn-lg button-orange mt-5" style="border-radius: 0; font-size: 1rem;" href="#contact" role="button">DIREKT KONTAKT AUFNEHMEN</a
          >
        </div>
      </div>
    </div>
  </div>
  <a id="services"></a>
  <!-- Background image -->
</header>
