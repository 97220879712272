import { Component } from '@angular/core';

@Component({
  selector: 'app-km-data-security',
  templateUrl: './km-data-security.component.html',
  styleUrls: ['./km-data-security.component.scss']
})
export class KmDataSecurityComponent {
  navbarCollapsed = false;
  constructor() { }

  ngOnInit() { }
}
