<footer class="text-center text-lg-start">

  <div class="dark-bg m-0">
    <div class="row m-0">
      <div class="text-start col-6 p-2 ps-5 mt-2 mb-2">
        <span class="fs-6">Copyright © 2023 Kältemeister - All Rights reserved.</span>
      </div>

      <div class="text-end col-6 p-2 pe-5 mt-2 mb-2">
        <a href="datenschutz" >Datenschutz</a>
        |
        <a href="impressum">Impressum</a>
      </div>
    </div>
  </div>

</footer>
