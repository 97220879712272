import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-km-footer',
  templateUrl: './km-footer.component.html',
  styleUrls: ['./km-footer.component.scss']
})
export class KmFooterComponent implements OnInit {
  constructor() { }

  ngOnInit() { }

}
