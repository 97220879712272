<div class="d-flex flex-column justify-content-center align-items-center blue-bg pt-5">
  <div class="text-white text-center w-75 mt-5 mb-5 m-0">
    <div class="row d-flex justify-content-center">
      <div class="col-md-6 text-center">
        <h1 class="contact-header">Kontakt</h1>
      </div>
    </div>
    <div *ngIf="!mobileView" class="row d-flex justify-content-center">
      <div class="col-sm-1">
        <hr class="hr" style="border-top: 3px solid white;"/>
      </div>
    </div>
    <div *ngIf="mobileView" class="row d-flex justify-content-center">
      <div class="col-4">
        <hr class="hr" style="border-top: 3px solid white;"/>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 mt-4 mb-3">
        <h4 class="mb-4 text-uppercase">Kältemeister</h4>
        <p class="mb-0">Manfred-von-Ardenne-Ring 20</p>
        <p class="mb-0">Haus D</p>
        <p class="mb-0">01099 Dresden</p>
      </div>
      <div class="col-md-12 mb-4">
        <p class="fw-bold">T: +49 351 8925240</p>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div *ngIf="!mobileView" class="col-sm-12 mb-4">
        <a class="white-bg m-4 fs-1 social-button" href="{{ linkedIn }}" target="_blank" rel="noopener noreferrer"
          role="button"><i class="fab fa-linkedin "></i></a>
        <a class="white-bg m-4 fs-1 social-button" href="{{ xing }}" target="_blank" rel="noopener noreferrer"
          role="button"><i class="fab fa-xing-square "></i></a>
        <a class="white-bg m-4 fs-1 social-button" href="{{ instagram }}" target="_blank" rel="noopener noreferrer"
          role="button"><i class="fab fa-instagram "></i></a>
      </div>
      <div *ngIf="mobileView" class="col-sm-12 mb-4">
        <a class="white-bg m-4 fs-1 social-button-mobile" href="{{ linkedIn }}" target="_blank" rel="noopener noreferrer"
          role="button"><i class="fab fa-linkedin "></i></a>
        <a class="white-bg m-4 fs-1 social-button-mobile" href="{{ xing }}" target="_blank" rel="noopener noreferrer"
          role="button"><i class="fab fa-xing-square "></i></a>
        <a class="white-bg m-4 fs-1 social-button-mobile" href="{{ instagram }}" target="_blank" rel="noopener noreferrer"
          role="button"><i class="fab fa-instagram "></i></a>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-6 mb-3">
        <form [formGroup]="validationForm" (ngSubmit)="onSubmit(validationForm.value)">
          <mdb-form-control class="mb-4 white-form-outline">
            <input
              mdbValidate
              mdbInput
              type="text"
              id="inputName"
              class="form-control w-100 text-white"
              aria-label="Eingabefenster für Namen"
              formControlName="inputName"
              required/>
            <label mdbLabel class="form-label text-white" for="inputName">Name</label>
            <mdb-error class="text-small" *ngIf="inputName?.invalid && (inputName?.dirty || inputName?.touched)">
              Name darf nicht leer sein!
            </mdb-error>
          </mdb-form-control>
          <mdb-form-control class="mb-4 white-form-outline">
            <input
              mdbValidate
              mdbInput
              type="email"
              id="typeEmail"
              class="form-control w-100 text-white"
              aria-label="Eingabefenster für E-Mail Adresse des Senders"
              formControlName="eMail"
              required/>
            <label mdbLabel class="form-label text-white" for="typeEmail">E-Mail</label>
            <mdb-error class="text-small" *ngIf="eMail?.invalid && (eMail?.dirty || eMail?.touched)"
              >Bitte eine gültige E-Mail Adresse eintragen!</mdb-error
            >
          </mdb-form-control>
          <mdb-form-control class="mb-4 white-form-outline">
            <textarea
              mdbValidate
              mdbInput
              type="text"
              class="form-control w-100 text-white"
              id="messageArea"
              formControlName="messageArea"
              aria-label="Eingabefenster für Nachricht an Kaeltemeister"
              rows="4"
              required></textarea>
            <label mdbLabel class="form-label text-white" for="messageArea">Nachricht</label>
            <mdb-error class="text-small" *ngIf="messageArea?.invalid && (messageArea?.dirty || messageArea?.touched)">
              Nachricht darf nicht leer sein!
            </mdb-error>
          </mdb-form-control>
          <button type="submit" class="btn m-4 button-orange" style="border-radius: 0; font-size: 1rem;" [disabled]="!validationForm.valid || waitingForResponse">
            <span *ngIf="!waitingForResponse">Senden</span>
            <span *ngIf="waitingForResponse" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="waitingForResponse"> Sende Mail...</span>
          </button>
          <button type="reset" class="btn m-4 button-white" style="border-radius: 0; font-size: 1rem;">Abbrechen</button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-12">
    <a href="{{ mapsLink }}" target="_blank" rel="noopener noreferrer">
      <img src="../../assets/img/Anfahrtsskizze.png" class="img-fluid w-100" alt="Anfahrt Kaeltemeister" />
    </a>
  </div>
</div>
