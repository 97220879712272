import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-km-contact-modal',
  templateUrl: './km-contact-modal.component.html',
  styleUrls: ['./km-contact-modal.component.scss']
})
export class KmContactModalComponent {
  success: boolean = true;

  constructor(public modalRef: MdbModalRef<KmContactModalComponent>) {}
}
