import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KmImpressumComponent } from './km-impressum/km-impressum.component';
import { KmDataSecurityComponent } from './km-data-security/km-data-security.component';
import { AppComponent } from './app.component';
import { KmMainContentComponent } from './km-main-content/km-main-content.component';

const routes: Routes = [
  { path: '', component: KmMainContentComponent },
  { path: 'datenschutz', component: KmDataSecurityComponent },
  { path: 'impressum', component: KmImpressumComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
