<!-- Navbar -->
<nav *ngIf="!mobileView" class="navbar sticky-top navbar-expand-lg navbar-bg shadow-0 w-100">
  <div class="container-fluid text-center justify-content-between">
    <button
      class="navbar-toggler"
      type="button"
      (click)="navigationComponent.toggle()"
      (click)="navbarCollapsed = !navbarCollapsed"
      aria-controls="navigationComponent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars" style="color: white;"></i>
    </button>
    <div
      mdbCollapse
      #navigationComponent="mdbCollapse"
      class="collapse navbar-collapse"
      id="navigationComponent"
    >
      <ul class="navbar-nav me-auto mb-2 mb-lg-1 fs-5">
        <li class="nav-item active nav-element">
          <a class="nav-link" aria-current="page" href="#">START</a>
        </li>
        <li class="nav-item nav-element">
          <a class="nav-link" href="#services">LEISTUNGEN</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-1 fs-5">
        <li class="nav-item nav-element">
          <a class="nav-link" href="#company">UNTERNEHMEN</a>
        </li>
        <li class="nav-item nav-element">
          <a class="nav-link" href="#contact">KONTAKT</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- Navbar -->
<!-- Navbar Mobile -->
<nav *ngIf="mobileView" class="navbar sticky-top navbar-expand-lg navbar-mobile-bg shadow-0 w-100">
  <div class="container-fluid text-center justify-content-between">
    <button class="navbar-toggler" type="button" (click)="navigationComponent.toggle()"
      (click)="navbarCollapsed = !navbarCollapsed" aria-controls="navigationComponent" aria-expanded="false"
      aria-label="Toggle navigation">
      <i class="fas fa-bars" style="color: white;"></i>
    </button>
    <div mdbCollapse #navigationComponent="mdbCollapse" class="collapse navbar-collapse" id="navigationComponent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-1 fs-5">
        <li class="nav-item active nav-element">
          <a class="nav-link" aria-current="page" href="#">START</a>
        </li>
        <li class="nav-item nav-element">
          <a class="nav-link" href="#services">LEISTUNGEN</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-1 fs-5">
        <li class="nav-item nav-element">
          <a class="nav-link" href="#company">UNTERNEHMEN</a>
        </li>
        <li class="nav-item nav-element">
          <a class="nav-link" href="#contact">KONTAKT</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- Navbar -->
