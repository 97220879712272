<div *ngIf="!mobileView" class="d-flex flex-column justify-content-center align-items-center mt-5 mb-5 m-0">
  <div class="text-white text-center w-75">
    <div class="row d-flex justify-content-center">
      <div class="service-header col-lg-6 text-center">
        <h1>Unsere Leistungen</h1>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-sm-1">
        <hr class="hr" style="border-top: 3px solid #00A4CB;"/>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center m-0">
    <div class="card w-75 shadow-0 m-5">
      <div class="row g-0 d-flex align-items-center justify-content-evenly">
        <div class="col-lg-5 p-2">
          <img
            src="../../assets/img/klimatisierung_kaeltemeister.jpg"
            alt="Klimatisierung Abbildung"
            class="img-fluid"
          />
        </div>
        <div class="col-lg-5 fs-6 text-center" [ngClass]="{'limitTextHeight': isClimateReadMore}">
          <div class="card-body text-center">
            <h3 class="card-title service-header text-uppercase">Klimatechnik</h3>
            <ul class="list-group list-group-light text-center">
                <li class="list-group-item">
                  <h5 class="fw-bold">Privat</h5>
                  <p class="mb-2">
                    Klimaanlagen für dein Zuhause und für die Umwelt: Wir verbauen ausschließlich energieeffiziente Kälte- und Klimatechnik.
                    So profitierst nicht nur du, sondern auch die Umwelt. Klimaanlagen sorgen für ein angenehmes Raumklima, beseitigen Schadstoffe
                    aus der Luft und verbessern so die Luftqualität in deinem Zuhause nachhaltig. Das große Extra: Im Winter heizen sie dein Zuhause kuschelig warm.
                  </p>
                </li>
                <li class="list-group-item ">
                  <h5 class="fw-bold">Gewerbe</h5>
                  <p class="mb-2">
                    Klimatechnik fürs Büro und Gewerbeimmobilien: Temperatur und Raumluft tragen nachweislich zu einem gesunden und produktiven Arbeitsklima bei.
                    Optimale Arbeitsbedingungen herrschen, wenn die Raumtemperatur zwischen 21 und 22&deg;C und die Luftfeuchtigkeit zwischen 40 und 60 % beträgt.
                    Ein solches Klima wird als angenehm empfunden und wirkt sich positiv auf Gesundheit und Leistungsfähigkeit deiner Teams aus. Um die Konzentration
                    deiner Mitarbeiter:innen zu unterstützen, verbauen unsere Kältemeister ausschließlich energieeffiziente und sehr leise Technik. Für Büros mit
                    mehreren Räumen eignen sich sehr gut Split-Geräte, da du so mehrere Kühlaggregate an einen außenliegenden Kompressor anschließen kannst. Alternativ
                    sind hochwertige Monoblöcke ebenfalls bestens geeignet für deine Büros. Die geräuscharmen Geräte sorgen für eine optimale Arbeitsatmosphäre.
                  </p>
                </li>
            </ul>
            <div class="read-more">
              <button *ngIf="isClimateReadMore" type="button" class="btn text-white button-orange" (click)="isClimateReadMore=!isClimateReadMore">
                Mehr erfahren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center m-0">
    <div class="card w-75 shadow-0 m-5">
      <div class="row g-0 d-flex align-items-center justify-content-evenly">
        <div class="col-lg-5 fs-6 text-center" [ngClass]="{'limitTextHeight': isHeatpumpReadMore}">
          <div class="card-body text-center">
            <h3 class="card-title service-header">WÄRMEPUMPEN</h3>
            <ul class="list-group list-group-light text-center">
              <li class="list-group-item ">
                Umweltfreundlich heizen aus Luft, Wasser und Erdreich: Wärme ist ein Grundbedürfnis des Menschen. Ein hoher Anteil fossiler Brennstoffe an unserer
                Energieversorgung schädigt unsere Umwelt massiv, da bei der Verbrennung hohe Schadstoffmengen, wie Schwefeldioxid und Stickoxide freigesetzt werden.
                Zudem solltest du aufgrund der zunehmenden Verknappung der fossilen Brennstoffe mit einer raschen Verteuerung rechnen. Hier kommen Wärmepumpen mit
                Tiefenbohrung, Luft, Wasser, Sole-Wasser Flächenkollektoren und Direktverdampfungs Flächenkollektoren ins Spiel. Bei den Kältemeistern kannst du dich
                auf eine qualifizierte Beratung verlassen, Preisstabilität und umfassenden Service. Gemeinsam mit dir realisieren wir effektive und bezahlbare Lösungen,
                mit denen du außerdem noch Geld sparst. Warum viel Geld für Heizenergie ausgeben, wenn die Wärme bereits in der Umwelt, im Boden, im Grundwasser etc. frei
                verfügbar ist und du nur eine Technologie benötigst, um sie in die Wohn oder Arbeitsräume zu befördern? Wärmepumpen nutzen bis zu 75 Prozent Umweltwärme,
                um Wärme zu erzeugen.
              </li>
            </ul>
            <div class="read-more">
              <button *ngIf="isHeatpumpReadMore" type="button" class="btn text-white button-orange" (click)="isHeatpumpReadMore=!isHeatpumpReadMore">
                Mehr erfahren
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-5 p-2">
          <img
            src="../../assets/img/waermepumpen_kaeltemeister.jpg"
            alt="Wärmepumpen Abbildung"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center m-0">
    <div class="card w-75 shadow-0 m-5">
      <div class="row g-0 d-flex align-items-center justify-content-evenly">
        <div class="col-lg-5 p-2">
          <img
            src="../../assets/img/vrv_systeme_kaeltemeister.jpg"
            alt="Kaeltetechnik Abbildung"
            class="img-fluid"
          />
        </div>
        <div class="col-lg-5 fs-6 text-center" [ngClass]="{'limitTextHeight': isColdtechReadMore}">
          <div class="card-body text-center">
            <h3 class="card-title service-header text-uppercase">Kältetechnik</h3>
            <ul class="list-group list-group-light text-center">
              <li class="list-group-item ">
                Genau wie du legen wir größten Wert auf Zuverlässigkeit und Effizienz. Daher installieren wir deine Kälteanlage nicht nur,
                sondern reinigen und warten sie auch regelmäßig. Dadurch verhindern wir eine Minderung der Kälteleistung oder einen
                erhöhten Stromverbrauch. Wir montieren und installieren zusätzlich Kaltwassersätze und bieten außerdem eine effiziente und sichere Prozesskühlung.
              </li>
              <li class="list-group-item ">
                Zukunftsfähige Industrieanlagen und Produktionsprozesse benötigen hochprofessionelle Kältetechnik,
                die Wasserkühlsätze (Chiller) und Turbotechnik mit energiesparenden Innovationen vereinen.
                Wir arbeiten mit Partnern, die platzsparende, energieeffiziente sowie Kältemittel reduzierende
                Systeme entwickeln und sich von dir leicht und funktional steuern lassen.
              </li>
            </ul>
            <div class="read-more">
              <button *ngIf="isColdtechReadMore" type="button" class="btn text-white button-orange" (click)="isColdtechReadMore=!isColdtechReadMore">
                Mehr erfahren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center m-0">
    <div class="card w-75 shadow-0 m-5">
      <div class="row g-0 d-flex align-items-center justify-content-evenly">
        <div class="col-lg-5 fs-6 text-center" [ngClass]="{'limitTextHeight': isServerReadMore}">
          <div class="card-body text-center">
            <h3 class="card-title service-header text-uppercase">Server&shy;raum&shy;kühlung</h3>
            <ul class="list-group list-group-light text-center">
              <li class="list-group-item ">
                EDV- und Serverraumkühlung: Die Digitalisierung erfordert immer mehr Rechner und Servertechnik. Ausfallsicherheit ist
                das zentrale Kriterium. Wir Kältemeister unterstützen dich dabei, indem wir deine Serverräume effizient kühlen.
                Dazu erstellen wir zuerst ein klimatechnisches Anforderungsprofil und setzen dann gemeinsam mit dir einen optimalen
                Kühlungsprozess auf. Auch im Notfall sind die Kältemeister schnell vor Ort und beheben technische Störungen der Kälte-
                und Klimaanlagen ohne lange Ausfallzeiten.
              </li>
            </ul>
            <div class="read-more">
              <button *ngIf="isServerReadMore" type="button" class="btn text-white button-orange" (click)="isServerReadMore=!isServerReadMore">
                Mehr erfahren
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-5 p-2">
          <img
            src="../../assets/img/serverkuehlung_kaeltemeister.jpg"
            alt="Service und Wartung Abbildung"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center m-0">
    <div class="card w-75 shadow-0 m-5">
      <div class="row g-0 d-flex align-items-center justify-content-evenly">
        <div class="col-lg-5 p-2">
          <img
            src="../../assets/img/kaeltemeister_dichtheitspruefung.JPG"
            alt="Dichtheitspruefung Abbildung"
            class="img-fluid"
          />
        </div>
        <div class="col-lg-5 fs-6 text-center" [ngClass]="{'limitTextHeight': isDensityCheckReadMore}">
          <div class="card-body text-center">
            <h3 class="card-title service-header text-uppercase">Dicht&shy;heits&shy;prüfung</h3>
            <ul class="list-group list-group-light text-center">
              <li class="list-group-item ">
                Zum Schutz der Umwelt und zur Reduzierung der Emissionen fluorierter Treibhausgase (F-Gase),
                setzt die Chemikalien-/ Klimaschutzverordnung (ChemKlimaschutzV) umfangreiche Pflichten für die Betreiber von Klima-, Kälte-
                und Wärmepumpenanlagen fest. So hast du als Betreiber von ortsfesten Anlagen, die klimaschädliche, halogenierte Treibhausgase
                enthalten, grundsätzlich dafür Sorge zu tragen, das Leckagen in den Kreisläufen umgehend entdeckt und abgedichtet werden.
                Diese ChemKlimaschutzV schreibt eine regelmäßige Dichtigkeitskontrolle einer Kälte-/Klima- und Wärmepumpenanlage vor, welche
                je nach Kältemittelfüllmenge durchgeführt werden muss.
              </li>
              <li class="list-group-item ">
                Wir warten deine kältetechnischen Geräte bzw. Anlagen und arbeiten Wartungspläne aus - nach Absprache mit dir und entsprechend
                den technischen Erfordernissen deiner Anlage. Nur bei regelmäßiger Überprüfung lassen sich größere Störungen und unzulässige
                Kältemittelemissionen vermeiden, die Betriebskosten senken und eine lange Nutzungsdauer gewährleisten.
              </li>
            </ul>
            <div class="read-more">
              <button *ngIf="isDensityCheckReadMore" type="button" class="btn text-white button-orange" (click)="isDensityCheckReadMore=!isDensityCheckReadMore">
                Mehr erfahren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center m-0">
    <div class="card m- w-75 shadow-0 m-5">
      <div class="row g-0 d-flex align-items-center justify-content-evenly">
        <div class="col-lg-5 fs-6 text-center" [ngClass]="{'limitTextHeight': isMaintenanceReadMore}">
          <div class="card-body text-center">
            <h3 class="card-title service-header text-uppercase">Service, Wartung und Reparatur</h3>
            <ul class="list-group list-group-light text-center">
              <li class="list-group-item ">
                Die Kältemeister sind deine Ansprechpartner Nr. 1 für Kälte und Klimatechnik in Raum Dresden. Wir übernehmen alle Service-,
                Wartungs- und Reparaturarbeiten deiner Kälte und Klimaanlagen.
              </li>
              <li class="list-group-item ">
                Regelmäßige Wartungen sind eine zentrale Voraussetzung für die störungsfreie und umweltschonende Funktion deiner Anlagen. Unsere
                Service-, Reparatur- und Wartungsleistungen stehen in Abhängigkeit zur installierenden Anlage und deinen individuellen Anforderungen.
                Durch EU-Verordnungen und nationale Gesetze bestehen zusätzlich umfassende Pflichten bezüglich Wartung, Zertifizierung und Protokollierung
                für die Betreiber von Klima- und Kälteanlagen. Wir empfehlen ein- bis zweimal im Jahr eine Wartung deiner Anlage durch unsere Kältemeister.
              </li>
              <li class="list-group-item ">
                Nach langjährigen Erfahrungen im Bereich der Kälte- und Klimatechnik wissen wir, worauf es ankommt und legen hohen Wert auf Qualität und
                reibungslose Abläufe. Schnelligkeit, Sauberkeit und Zuverlässigkeit sind unsere Werte.
              </li>
            </ul>
            <div class="read-more">
              <button *ngIf="isMaintenanceReadMore" type="button" class="btn text-white button-orange" (click)="isMaintenanceReadMore=!isMaintenanceReadMore">
                Mehr erfahren
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-5 p-2">
          <img
            src="../../assets/img/maintenance_kaeltemeister.jpg"
            alt="Service und Wartung Abbildung"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center m-0">
    <div class="card w-75 shadow-0 m-5">
      <div class="row g-0 d-flex align-items-center justify-content-evenly">
        <div class="col-lg-5 p-2">
          <img
            src="../../assets/img/beratung_kaeltemeister.jpg"
            alt="Beratung Abbildung"
            class="img-fluid"
          />
        </div>
        <div class="col-lg-5 fs-6 text-center" [ngClass]="{'limitTextHeight': isConsultingReadMore}">
          <div class="card-body text-center">
            <h3 class="card-title service-header text-uppercase">Planung & Beratung</h3>
            <ul class="list-group list-group-light text-center">
              <li class="list-group-item ">
                <h5 class="fw-bold">Beratung</h5>
                <p class="mb-2">
                  Freu dich auf eine umfassende Beratung durch unsere kompetenten Kältemeister rund um die Themen Wärmepumpen, Kälte- und Klimatechnik.
                  Unsere Experten überzeugen mit Erfahrung, Kompetenz, Präzision, Zuverlässigkeit und einem erstklassigen Service.
                </p>
                <p class="mb-0">
                  Eine optimal eingestellte und gewartete Kälteanlage hält die Temperatur unabhängig von den äußeren Wetterverhältnissen im Inneren
                  eines Gebäudes stabil und das bei gleichbleibendem Energieverbrauch. Die exakte Planung und Integration der Kältetechnik in die
                  Gebäudetechnik und -prozesse ermöglicht eine Kostenersparnis sowohl bei der Ansc haffung als auch im Betrieb. Der Einsatz
                  klimafreundlicher Kältemittel gewinnt dabei zunehmend an Bedeutung.
                </p>
              <li class="list-group-item">
                <h5 class="fw-bold">Planung</h5>
                <p class="mb-2">
                  Unsere Kältemeister analysieren mit dir gemeinsam deine aktuelle Ausgangssituation, deinen Bedarf und fertigen einen individuellen,
                  klimatechnischen Anforderungs- und Installationsplan. Dieser enthält:
                </p>
                <ul class="text-start" style="list-style-type:none;">
                  <li class="p-1"><span class='km-icon'></span> eine Kältebedarfsberechnung für deine privaten oder gewerblichen Räumlichkeiten</li>
                  <li class="p-1"><span class='km-icon'></span> Installationsstandorte für eine perfekte Luftverteilung deiner Anlagen</li>
                  <li class="p-1"><span class='km-icon'></span> Auslegung der Rohrleitungen und Dimensionierung der Geräte</li>
                  <li class="p-1"><span class='km-icon'></span> Auswahl des passenden Systems mit optimalen Preis-Leistung-Verhältnis</li>
                </ul>
              </li>
            </ul>
            <div class="read-more">
              <button *ngIf="isConsultingReadMore" type="button" class="btn text-white button-orange" (click)="isConsultingReadMore=!isConsultingReadMore">
                Mehr erfahren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a id="company"></a>
</div>

<div *ngIf="mobileView" class="d-flex flex-column justify-content-center align-items-center mt-5 mb-5 m-0">
  <div class="text-white text-center w-75">
    <div class="row d-flex justify-content-center">
      <div class="service-header col-lg-6 text-center">
        <h1>Unsere Leistungen</h1>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-4">
        <hr class="hr" style="border-top: 3px solid #00A4CB;"/>
      </div>
    </div>
  </div>
  <div class="card m-3 w-75 shadow-0">
    <div class="row g-0 d-flex align-items-center justify-content-center">
      <div class="col-lg-6 p-4">
        <img
          src="../../assets/img/klimatisierung_kaeltemeister.jpg"
          alt="Klimatisierung Abbildung"
          class="img-fluid"
        />
      </div>
      <div class="col-lg-6 fs-6 text-center" [ngClass]="{'limitTextHeight': isClimateReadMore}">
        <div class="card-body text-center">
          <h3 class="card-title service-header text-uppercase">Klimatechnik</h3>
          <ul class="list-group list-group-light text-center">
              <li class="list-group-item">
                <h5 class="fw-bold">Privat</h5>
                <p class="mb-2">
                  Klimaanlagen für dein Zuhause und für die Umwelt: Wir verbauen ausschließlich energieeffiziente Kälte- und Klimatechnik.
                  So profitierst nicht nur du, sondern auch die Umwelt. Klimaanlagen sorgen für ein angenehmes Raumklima, beseitigen Schadstoffe
                  aus der Luft und verbessern so die Luftqualität in deinem Zuhause nachhaltig. Das große Extra: Im Winter heizen sie dein Zuhause kuschelig warm.
                </p>
              </li>
              <li class="list-group-item ">
                <h5 class="fw-bold">Gewerbe</h5>
                <p class="mb-2">
                  Klimatechnik fürs Büro und Gewerbeimmobilien: Temperatur und Raumluft tragen nachweislich zu einem gesunden und produktiven Arbeitsklima bei.
                  Optimale Arbeitsbedingungen herrschen, wenn die Raumtemperatur zwischen 21 und 22&deg;C und die Luftfeuchtigkeit zwischen 40 und 60 % beträgt.
                  Ein solches Klima wird als angenehm empfunden und wirkt sich positiv auf Gesundheit und Leistungsfähigkeit deiner Teams aus. Um die Konzentration
                  deiner Mitarbeiter:innen zu unterstützen, verbauen unsere Kältemeister ausschließlich energieeffiziente und sehr leise Technik. Für Büros mit
                  mehreren Räumen eignen sich sehr gut Split-Geräte, da du so mehrere Kühlaggregate an einen außenliegenden Kompressor anschließen kannst. Alternativ
                  sind hochwertige Monoblöcke ebenfalls bestens geeignet für deine Büros. Die geräuscharmen Geräte sorgen für eine optimale Arbeitsatmosphäre.
                </p>
              </li>
          </ul>
          <div class="read-more">
            <button *ngIf="isClimateReadMore" type="button" class="btn text-white button-orange" (click)="isClimateReadMore=!isClimateReadMore">
              Mehr erfahren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card m-3 w-75 shadow-0">
    <div class="row g-0 d-flex align-items-center justify-content-center">
      <div class="col-lg-6 p-4">
        <img
          src="../../assets/img/waermepumpen_kaeltemeister.jpg"
          alt="Wärmepumpen Abbildung"
          class="img-fluid"
        />
      </div>
      <div class="col-lg-6 fs-6 text-center" [ngClass]="{'limitTextHeight': isHeatpumpReadMore}">
        <div class="card-body text-center">
          <h3 class="card-title service-header">WÄRMEPUMPEN</h3>
          <ul class="list-group list-group-light text-center">
            <li class="list-group-item ">
              Umweltfreundlich heizen aus Luft, Wasser und Erdreich: Wärme ist ein Grundbedürfnis des Menschen. Ein hoher Anteil fossiler Brennstoffe an unserer
              Energieversorgung schädigt unsere Umwelt massiv, da bei der Verbrennung hohe Schadstoffmengen, wie Schwefeldioxid und Stickoxide freigesetzt werden.
              Zudem solltest du aufgrund der zunehmenden Verknappung der fossilen Brennstoffe mit einer raschen Verteuerung rechnen. Hier kommen Wärmepumpen mit
              Tiefenbohrung, Luft, Wasser, Sole-Wasser Flächenkollektoren und Direktverdampfungs Flächenkollektoren ins Spiel. Bei den Kältemeistern kannst du dich
              auf eine qualifizierte Beratung verlassen, Preisstabilität und umfassenden Service. Gemeinsam mit dir realisieren wir effektive und bezahlbare Lösungen,
              mit denen du außerdem noch Geld sparst. Warum viel Geld für Heizenergie ausgeben, wenn die Wärme bereits in der Umwelt, im Boden, im Grundwasser etc. frei
              verfügbar ist und du nur eine Technologie benötigst, um sie in die Wohn oder Arbeitsräume zu befördern? Wärmepumpen nutzen bis zu 75 Prozent Umweltwärme,
              um Wärme zu erzeugen.
            </li>
          </ul>
          <div class="read-more">
            <button *ngIf="isHeatpumpReadMore" type="button" class="btn text-white button-orange" (click)="isHeatpumpReadMore=!isHeatpumpReadMore">
              Mehr erfahren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card m-3 w-75 shadow-0">
    <div class="row g-0 d-flex align-items-center justify-content-center">
      <div class="col-lg-6 p-4">
        <img
          src="../../assets/img/vrv_systeme_kaeltemeister.jpg"
          alt="Kaeltetechnik Abbildung"
          class="img-fluid"
        />
      </div>
      <div class="col-lg-6 fs-6 text-center" [ngClass]="{'limitTextHeight': isColdtechReadMore}">
        <div class="card-body text-center">
          <h3 class="card-title service-header text-uppercase">Kältetechnik</h3>
          <ul class="list-group list-group-light text-center">
            <li class="list-group-item ">
              Genau wie du legen wir größten Wert auf Zuverlässigkeit und Effizienz. Daher installieren wir deine Kälteanlage nicht nur,
              sondern reinigen und warten sie auch regelmäßig. Dadurch verhindern wir eine Minderung der Kälteleistung oder einen
              erhöhten Stromverbrauch. Wir montieren und installieren zusätzlich Kaltwassersätze und bieten außerdem eine effiziente und sichere Prozesskühlung.
            </li>
            <li class="list-group-item ">
              Zukunftsfähige Industrieanlagen und Produktionsprozesse benötigen hochprofessionelle Kältetechnik,
              die Wasserkühlsätze (Chiller) und Turbotechnik mit energiesparenden Innovationen vereinen.
              Wir arbeiten mit Partnern, die platzsparende, energieeffiziente sowie Kältemittel reduzierende
              Systeme entwickeln und sich von dir leicht und funktional steuern lassen.
            </li>
          </ul>
          <div class="read-more">
            <button *ngIf="isColdtechReadMore" type="button" class="btn text-white button-orange" (click)="isColdtechReadMore=!isColdtechReadMore">
              Mehr erfahren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card m-3 w-75 shadow-0">
    <div class="row g-0 d-flex align-items-center justify-content-center">
      <div class="col-lg-6 p-4">
        <img
          src="../../assets/img/serverkuehlung_kaeltemeister.jpg"
          alt="Service und Wartung Abbildung"
          class="img-fluid"
        />
      </div>
      <div class="col-lg-6 fs-6 text-center" [ngClass]="{'limitTextHeight': isServerReadMore}">
        <div class="card-body text-center">
          <h3 class="card-title service-header text-uppercase">Server&shy;raum&shy;kühlung</h3>
          <ul class="list-group list-group-light text-center">
            <li class="list-group-item ">
              EDV- und Serverraumkühlung: Die Digitalisierung erfordert immer mehr Rechner und Servertechnik. Ausfallsicherheit ist
              das zentrale Kriterium. Wir Kältemeister unterstützen dich dabei, indem wir deine Serverräume effizient kühlen.
              Dazu erstellen wir zuerst ein klimatechnisches Anforderungsprofil und setzen dann gemeinsam mit dir einen optimalen
              Kühlungsprozess auf. Auch im Notfall sind die Kältemeister schnell vor Ort und beheben technische Störungen der Kälte-
              und Klimaanlagen ohne lange Ausfallzeiten.
            </li>
          </ul>
          <div class="read-more">
            <button *ngIf="isServerReadMore" type="button" class="btn text-white button-orange" (click)="isServerReadMore=!isServerReadMore">
              Mehr erfahren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card m-3 w-75 shadow-0">
    <div class="row g-0 d-flex align-items-center justify-content-center">
      <div class="col-lg-6 p-4">
        <img
          src="../../assets/img/kaeltemeister_dichtheitspruefung.JPG"
          alt="Dichtheitspruefung Abbildung"
          class="img-fluid"
        />
      </div>
      <div class="col-lg-6 fs-6 text-center" [ngClass]="{'limitTextHeight': isDensityCheckReadMore}">
        <div class="card-body text-center">
          <h3 class="card-title service-header text-uppercase">Dicht&shy;heits&shy;prüfung</h3>
          <ul class="list-group list-group-light text-center">
            <li class="list-group-item ">
              Zum Schutz der Umwelt und zur Reduzierung der Emissionen fluorierter Treibhausgase (F-Gase),
              setzt die Chemikalien-/ Klimaschutzverordnung (ChemKlimaschutzV) umfangreiche Pflichten für die Betreiber von Klima-, Kälte-
              und Wärmepumpenanlagen fest. So hast du als Betreiber von ortsfesten Anlagen, die klimaschädliche, halogenierte Treibhausgase
              enthalten, grundsätzlich dafür Sorge zu tragen, das Leckagen in den Kreisläufen umgehend entdeckt und abgedichtet werden.
              Diese ChemKlimaschutzV schreibt eine regelmäßige Dichtigkeitskontrolle einer Kälte-/Klima- und Wärmepumpenanlage vor, welche
              je nach Kältemittelfüllmenge durchgeführt werden muss.
            </li>
            <li class="list-group-item ">
              Wir warten deine kältetechnischen Geräte bzw. Anlagen und arbeiten Wartungspläne aus - nach Absprache mit dir und entsprechend
              den technischen Erfordernissen deiner Anlage. Nur bei regelmäßiger Überprüfung lassen sich größere Störungen und unzulässige
              Kältemittelemissionen vermeiden, die Betriebskosten senken und eine lange Nutzungsdauer gewährleisten.
            </li>
          </ul>
          <div class="read-more">
            <button *ngIf="isDensityCheckReadMore" type="button" class="btn text-white button-orange" (click)="isDensityCheckReadMore=!isDensityCheckReadMore">
              Mehr erfahren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card m-3 w-75 shadow-0">
    <div class="row g-0 d-flex align-items-center justify-content-center">
      <div class="col-lg-6 p-4">
        <img
          src="../../assets/img/maintenance_kaeltemeister.jpg"
          alt="Service und Wartung Abbildung"
          class="img-fluid"
        />
      </div>
      <div class="col-lg-6 fs-6 text-center" [ngClass]="{'limitTextHeight': isMaintenanceReadMore}">
        <div class="card-body text-center">
          <h3 class="card-title service-header text-uppercase">Service, Wartung und Reparatur</h3>
          <ul class="list-group list-group-light text-center">
            <li class="list-group-item ">
              Die Kältemeister sind deine Ansprechpartner Nr. 1 für Kälte und Klimatechnik in Raum Dresden. Wir übernehmen alle Service-,
              Wartungs- und Reparaturarbeiten deiner Kälte und Klimaanlagen.
            </li>
            <li class="list-group-item ">
              Regelmäßige Wartungen sind eine zentrale Voraussetzung für die störungsfreie und umweltschonende Funktion deiner Anlagen. Unsere
              Service-, Reparatur- und Wartungsleistungen stehen in Abhängigkeit zur installierenden Anlage und deinen individuellen Anforderungen.
              Durch EU-Verordnungen und nationale Gesetze bestehen zusätzlich umfassende Pflichten bezüglich Wartung, Zertifizierung und Protokollierung
              für die Betreiber von Klima- und Kälteanlagen. Wir empfehlen ein- bis zweimal im Jahr eine Wartung deiner Anlage durch unsere Kältemeister.
            </li>
            <li class="list-group-item ">
              Nach langjährigen Erfahrungen im Bereich der Kälte- und Klimatechnik wissen wir, worauf es ankommt und legen hohen Wert auf Qualität und
              reibungslose Abläufe. Schnelligkeit, Sauberkeit und Zuverlässigkeit sind unsere Werte.
            </li>
          </ul>
          <div class="read-more">
            <button *ngIf="isMaintenanceReadMore" type="button" class="btn text-white button-orange" (click)="isMaintenanceReadMore=!isMaintenanceReadMore">
              Mehr erfahren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card m-3 w-75 shadow-0">
    <div class="row g-0 d-flex align-items-center justify-content-center">
      <div class="col-lg-6 p-4">
        <img
          src="../../assets/img/beratung_kaeltemeister.jpg"
          alt="Beratung Abbildung"
          class="img-fluid"
        />
      </div>
      <div class="col-lg-6 fs-6 text-center" [ngClass]="{'limitTextHeight': isConsultingReadMore}">
        <div class="card-body text-center">
          <h3 class="card-title service-header text-uppercase">Planung & Beratung</h3>
          <ul class="list-group list-group-light text-center">
            <li class="list-group-item ">
              <h5 class="fw-bold">Beratung</h5>
              <p class="mb-2">
                Freu dich auf eine umfassende Beratung durch unsere kompetenten Kältemeister rund um die Themen Wärmepumpen, Kälte- und Klimatechnik.
                Unsere Experten überzeugen mit Erfahrung, Kompetenz, Präzision, Zuverlässigkeit und einem erstklassigen Service.
              </p>
              <p class="mb-0">
                Eine optimal eingestellte und gewartete Kälteanlage hält die Temperatur unabhängig von den äußeren Wetterverhältnissen im Inneren
                eines Gebäudes stabil und das bei gleichbleibendem Energieverbrauch. Die exakte Planung und Integration der Kältetechnik in die
                Gebäudetechnik und -prozesse ermöglicht eine Kostenersparnis sowohl bei der Ansc haffung als auch im Betrieb. Der Einsatz
                klimafreundlicher Kältemittel gewinnt dabei zunehmend an Bedeutung.
              </p>
            <li class="list-group-item">
              <h5 class="fw-bold">Planung</h5>
              <p class="mb-2">
                Unsere Kältemeister analysieren mit dir gemeinsam deine aktuelle Ausgangssituation, deinen Bedarf und fertigen einen individuellen,
                klimatechnischen Anforderungs- und Installationsplan. Dieser enthält:
              </p>
              <ul class="text-start p-0 m-0" style="list-style-type:none;">
                <li class="p-1"><span class='km-icon'></span> eine Kältebedarfsberechnung für deine privaten oder gewerblichen Räumlichkeiten</li>
                <li class="p-1"><span class='km-icon'></span> Installationsstandorte für eine perfekte Luftverteilung deiner Anlagen</li>
                <li class="p-1"><span class='km-icon'></span> Auslegung der Rohrleitungen und Dimensionierung der Geräte</li>
                <li class="p-1"><span class='km-icon'></span> Auswahl des passenden Systems mit optimalen Preis-Leistung-Verhältnis</li>
              </ul>
            </li>
          </ul>
          <div class="read-more">
            <button *ngIf="isConsultingReadMore" type="button" class="btn text-white button-orange" (click)="isConsultingReadMore=!isConsultingReadMore">
              Mehr erfahren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a id="company"></a>
</div>
