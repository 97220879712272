import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../contact.service';
import { KmContactModalComponent } from '../km-contact-modal/km-contact-modal.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-km-contact',
  templateUrl: './km-contact.component.html',
  styleUrls: ['./km-contact.component.scss']
})
export class KmContactComponent implements OnInit {
  validationForm: FormGroup;
  linkedIn = 'https://www.linkedin.com/in/falk-herrmann-13b135203';
  instagram = 'https://www.instagram.com/kaeltemeister1/?hl=de';
  xing = 'https://www.instagram.com/kaeltemeister1/?hl=de';
  mapsLink = 'https://goo.gl/maps/fMszquxbkSnW422a9';

  modalRef: MdbModalRef<KmContactModalComponent> | null = null;
  mobileView = false;
  waitingForResponse = false;

  constructor(private contact: ContactService, private modalService: MdbModalService, public breakpointObserver: BreakpointObserver) {
    this.validationForm = new FormGroup({
      eMail: new FormControl(null, { validators: [Validators.required, Validators.email], updateOn: 'change' }),
      inputName: new FormControl(null, { validators: [Validators.required], updateOn: 'change' }),
      messageArea: new FormControl(null, { validators: [Validators.required], updateOn: 'change' })
    });
  }

  get eMail(): AbstractControl {
    return this.validationForm.get('eMail')!;
  }

  get inputName(): AbstractControl {
    return this.validationForm.get('inputName')!;
  }

  get messageArea(): AbstractControl {
    return this.validationForm.get('messageArea')!;
  }

  onSubmit(validationForm: any): void {
    this.waitingForResponse = true;
    this.contact.PostMessage(validationForm)
      .subscribe(response => {
        this.waitingForResponse = false;
        this.modalRef = this.modalService.open(KmContactModalComponent, {
          data: { success: true },
        });
      }, error => {
        console.warn(error.responseText)
        console.log({ error })
        this.waitingForResponse = false;
        this.modalRef = this.modalService.open(KmContactModalComponent, {
          data: { success: false },
        });
      })
    this.validationForm.reset();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView = true;
        } else {
          this.mobileView = false;
        }
      });
  }
}
