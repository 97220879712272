<div class="d-flex flex-column justify-content-center align-items-center dark-bg pt-5 pb-5">
  <div class="text-white text-center w-75 mt-5 m-0">
    <div class="row d-flex justify-content-center">
      <div class="company-header col-lg-6 text-center">
        <h1>Firma Kältemeister</h1>
      </div>
    </div>
    <div *ngIf="!mobileView" class="row d-flex justify-content-center">
      <div class="col-sm-1">
        <hr class="hr" style="border-top: 3px solid #00A4CB;"/>
      </div>
    </div>
    <div *ngIf="mobileView" class="row d-flex justify-content-center">
      <div class="col-4">
        <hr class="hr" style="border-top: 3px solid #00A4CB;"/>
      </div>
    </div>
  </div>
  <div class="text-white text-center w-75">
    <div class="row d-flex justify-content-center mt-5 m-0">
      <div class="col-lg-6">
        <p>
          Falk Herrmann und die Kältemeister stehen für erstklassige Beratung und Service rund um nachhaltige und energieeffiziente Kälte-
          und Klimatechnik und Wärmepumpen. 2022 mit Sitz in Dresden gegründet, schätzen unsere Kunden und unsere mehr als 25-jährige
          persönliche Erfahrung von der professionellen Beratung auf Augenhöhe bis hin zur gewissenhaften Auftragsabwicklung. Ob
          Beratung, Planung, Installation, Wartung oder Instandsetzung - wir begleiten dich gerne bei jedem Schritt deines Projekts. Egal ob es
          sich um Gastronomie, Industrie, Gewerbe oder um ein Privatprojekt handelt.
        </p>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-1 mb-4">
      <div class="col-lg-6">
        <p>
          Jetzt mehr erfahren über unsere <a href="#services" class="link-info fw-bold">Leistungen</a>.
        </p>
      </div>
    </div>
    <div *ngIf="!mobileView" class="row d-flex justify-content-center">
      <div class="col-lg-4 p-2 mb-3">
        <img
          src="../../assets/img/falk_herrmann_kaeltermeister_profile.png"
          class="img-fluid"
          alt="Portrait Falk Herrmann"
        />
      </div>
    </div>
    <div *ngIf="mobileView" class="row d-flex justify-content-center">
      <div class="col-lg-4 p-2 mb-3">
        <img
          src="../../assets/img/falk_herrmann_kaeltermeister_profile.png"
          class="img-fluid rounded-3 w-75"
          alt="Portrait Falk Herrmann"
        />
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-lg-5 mt-3 mb-3">
        <h3 class="company-header">FALK HERRMANN</h3>
        <p class="fs-4">Kälteanlagenbaumeister & Inhaber</p>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-lg-12 mt-5 m-0 text-center">
      <h1 class="company-header">
        »Karriere als Kältemeister wird cool«
      </h1>
    </div>
  </div>
  <div class="text-white text-center w-75">
    <div *ngIf="!mobileView" class="row d-flex justify-content-center">
      <div class="col-sm-1">
        <hr class="hr" style="border-top: 3px solid #00A4CB;"/>
      </div>
    </div>
    <div *ngIf="mobileView" class="row d-flex justify-content-center">
      <div class="col-4">
        <hr class="hr" style="border-top: 3px solid #00A4CB;"/>
      </div>
    </div>
  </div>
  <div *ngIf="!mobileView">
    <div class="row d-flex justify-content-center mt-5 m-0">
      <div class="card m-2 w-75 shadow-0 dark-bg m-1">
        <div class="row g-0 d-flex align-items-center justify-content-evenly">
          <div class="col-lg-5 p-2">
            <img
              src="../../assets/img/Kaeltemeister-Website-Karriere-Motiv-1.jpg"
              alt="Klimatisierung Abbildung"
              class="img-fluid "
            />
          </div>
          <div class="col-lg-5 fs-6">
            <div class="card-body text-center">
              <h3 class="card-title service-header text-uppercase text-blue mb-4">DARAUF KANNST DU DICH FREUEN:</h3>
              <ul class="text-center text-white fs-5" style="list-style-type:none;">
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Attraktive Bezahlung</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Eine abwechslungsreiche und anspruchsvolle Arbeit</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Installation von Klimaanlagen, Kälteanlagen und Wärmepumpen</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Montagearbeiten im gewerblichen, industriellen, gastronomischen und im privaten Bereich</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Ein freundliches und dynamisches Arbeitsumfeld</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Wertvolle Arbeitserfahrungen in einer immer wichtiger werdenden Zukunftsbranche</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Modernste Technik</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Berufliche Weiterentwicklungsmöglichkeiten in unserem Betrieb</li>
              </ul> me-2
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center m-0">
      <div class="card m-2 w-75 shadow-0 dark-bg m-5">
        <div class="row g-0 d-flex align-items-center justify-content-evenly">
          <div class="col-lg-5 fs-6">
            <div class="card-body text-center">
              <h3 class="card-title service-header text-uppercase text-blue mb-4">Das bringst <wbr>du&nbsp;mit:</h3>
              <ul class="text-center text-white fs-5" style="list-style-type:none;">
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Interesse an den Bereichen Mechatronik und Elektrotechnik</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Zuverlässigkeit und Teamfähigkeit</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Eine hohe Einsatzbereitschaft</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-5 p-2">
            <img
              src="../../assets/img/Kaeltemeister-Website-Karriere-Motiv-2.jpg"
              alt="Klimatisierung Abbildung"
              class="img-fluid "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center m-0">
      <div class="card m-2 w-75 shadow-0 dark-bg m-5">
        <div class="row g-0 d-flex align-items-center justify-content-evenly">
          <div class="col-lg-5 p-2">
            <img
              src="../../assets/img/Kaeltemeister-Website-Karriere-Motiv-3.jpg"
              alt="Klimatisierung Abbildung"
              class="img-fluid "
            />
          </div>
          <div class="col-lg-5 fs-6">
            <div class="card-body text-center">
              <h3 class="card-title service-header text-uppercase text-blue mb-4">KLINGT GUT FÜR DICH? Oder bist du noch unsicher und brauchst vorab mehr Infos?</h3>
              <p class="text-center text-white fs-5">
                  Ruf mich einfach an, schreib mir eine WhatsApp oder E-Mail, dann besprechen wir alles weitere bzw. schauen,
                  ob Mechatroniker:in für Kältetechnik etwas für dich sein könnte <i class="fas fa-angle-double-right"></i> <a href="#contact" class="link-info fw-bold"> Kontakt aufnehmen</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="mobileView">
    <div class="row d-flex justify-content-center m-0">
      <div class="card m-2 w-75 shadow-0 dark-bg">
        <div class="row g-0 d-flex align-items-center justify-content-center">
          <div class="col-lg-6 p-4">
            <img
              src="../../assets/img/Kaeltemeister-Website-Karriere-Motiv-1.jpg"
              alt="Klimatisierung Abbildung"
              class="img-fluid "
            />
          </div>
          <div class="col-lg-6 fs-6">
            <div class="card-body text-center">
              <h3 class="card-title service-header text-uppercase text-blue">DARAUF KANNST DU DICH FREUEN:</h3>
              <ul class="text-center text-white fs-5 p-0 m-0" style="list-style-type:none;">
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Attraktive Bezahlung</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Eine abwechslungsreiche und anspruchsvolle Arbeit</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Installation von Klimaanlagen, Kälteanlagen und Wärmepumpen</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Montagearbeiten im gewerblichen, industriellen, gastronomischen und im privaten Bereich</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Ein freundliches und dynamisches Arbeitsumfeld</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Wertvolle Arbeitserfahrungen in einer immer wichtiger werdenden Zukunftsbranche</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Modernste Technik</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Berufliche Weiterentwicklungsmöglichkeiten in unserem Betrieb</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center m-0">
      <div class="card m-2 w-75 shadow-0 dark-bg">
        <div class="row g-0 d-flex align-items-center justify-content-center">
          <div class="col-lg-6 p-4">
            <img
              src="../../assets/img/Kaeltemeister-Website-Karriere-Motiv-2.jpg"
              alt="Klimatisierung Abbildung"
              class="img-fluid "
            />
          </div>
          <div class="col-lg-6 fs-6">
            <div class="card-body text-center">
              <h3 class="card-title service-header text-uppercase text-blue">Das bringst <wbr>du&nbsp;mit:</h3>
              <ul class="text-center text-white fs-5 p-0 m-0" style="list-style-type:none;">
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Interesse an den Bereichen Mechatronik und Elektrotechnik</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Zuverlässigkeit und Teamfähigkeit</li>
                  <li class="p-1"><i class="fas fa-check text-blue me-2"></i> Eine hohe Einsatzbereitschaft</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center m-0">
      <div class="card m-2 w-75 shadow-0 dark-bg">
        <div class="row g-0 d-flex align-items-center justify-content-center">
          <div class="col-lg-6 p-4">
            <img
              src="../../assets/img/Kaeltemeister-Website-Karriere-Motiv-3.jpg"
              alt="Klimatisierung Abbildung"
              class="img-fluid "
            />
          </div>
          <div class="col-lg-6 fs-6">
            <div class="card-body text-center">
              <h3 class="card-title service-header text-uppercase text-blue">KLINGT GUT FÜR DICH? Oder bist du noch unsicher und brauchst vorab mehr Infos?</h3>
              <p class="text-center text-white fs-5">
                  Ruf mich einfach an, schreib mir eine WhatsApp oder E-Mail, dann besprechen wir alles weitere bzw. schauen,
                  ob Mechatroniker:in für Kältetechnik etwas für dich sein könnte <i class="fas fa-angle-double-right"></i> <a href="#contact" class="link-info fw-bold"> Kontakt aufnehmen</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
